import config from '#config';
import { useEffect } from 'react';

import analytics from '#utils/analytics';
import { scrollToTop } from '#utils/scroll';

const Analytics = () => {
  useEffect(() => {
    const isHyperLinkedPage = Boolean(location.hash);
    if (config.ANALYTICS) analytics.init();
    if (!isHyperLinkedPage) scrollToTop();
  }, []);
  return '';
};

export default Analytics;
